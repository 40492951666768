div.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem;
    gap: 1rem;

    &.not-available {
        justify-content: center;
    }

    & > h2 {
        width: 100%;
        max-width: 600px;
        text-align: start;
        padding-bottom: 1rem;
    }
}

div.overview-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

div.overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 600px;

    & > div.overview-labels {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        align-items: center;

        & > div.end {
            justify-self: end;
            text-align: end;
        }
    }
}

div.overview-nav-bar {
    display: flex;
    padding: 2rem;
    justify-content: center;

    & > div {
        display: flex;
        gap: 1rem;
        padding: 1rem;
        box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
        border-radius: 0.5rem;
    }

    & > div > div {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background: white;

        &.selected {
            background: #e6e6e6;
        }

        &:hover {
            background: #f1f1f1;
            cursor: pointer;
        }
    }
}

div.exercise-attempt-result-item {
    width: 100%;
    max-width: 600px;

    & > a {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0.5rem;
        padding: 0.5rem;
        background: #f4f4f4;
        width: 100%;
        transition: all 200ms ease;

        &:hover {
            background: #f1f1f1;
        }
    }
}