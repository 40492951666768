.user-story-split {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    text-align: start;
}

.user-story-label {
    text-align: end;
    font-weight: bold;
}

.left-column {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.single-column {
    display: grid;
    height: 100%;
    width: 100%;
}

.multi-column {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    height: 100%;
    width: 100%;
}

div.tutorial-content-interactive-view {
    display: grid;
    grid-template-columns: 2fr 7fr 2fr;
    background: #e4e4e4;
    overflow: hidden;
    width: 100%;
    /*height: 80vh;*/
    gap: 0.5rem;
    padding: 0.5rem 0;
}

div.tutorial-side-bar-left, div.tutorial-side-bar-right, div.tutorial-content-center-container {
    background: white;
    width: 100%;
    height: 80vh;
}

div.tutorial-content-center-container {
    display: grid;
    grid-template-rows: 3fr 1fr;

    & > div.tutorial-content-center-container-source {
        grid-row: 2;
    }

    & > div.tutorial-content-center-container-target {
        grid-row: 1;
    }
}

div.tutorial-side-bar-right {
    text-align: start;
}