div.tracing-view-tabs {
    position: absolute;
    display: flex;
    gap: 0.25rem;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background: white;
    padding: 0.5rem;
    margin-top: 1rem;
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
}

div.tracing-view-tabs > div.tracing-view-tab-item {
    border-radius: 0.25rem;
    padding: 0.5rem;
    cursor: pointer;
}

div.tracing-view-tabs > div.tracing-view-tab-item.selected {
    background: #e6e6e6;
}