.newSprint {
    background-color: #f2f2f2;
}

.deleteSprintButton {
    float: right;
}

/* List item */
.collaboratorListItem {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.collaboratorName {
    place-self: center left;
}

.deleteCollaboratorButton {
    justify-self: end;
}