.us_inputGroup {
    margin-top: 20px;
}

.progress-bar {
    background-color: #00dc89;
    margin-top: 5px;
}

.progress-bar-label {
    margin-right: 10px;
}