#product-backlog-view{
    position: absolute;
    width:100%;
    height:100%;
}

.allZones {
    display: flex;
    margin-top: 10px;
    margin-left: 270px;
    position:relative;
    z-index: 0;
}