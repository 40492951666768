div.matrix-view-container {
    background: white;
    padding: 6rem 2rem;
    overflow-y: scroll;
}

table.matrix-view {
    width: 100%;
    text-align: center;
}

td.entity-item {
    text-align: end;
}

table.matrix-view > tbody > tr > td > div, table.matrix-view > thead > tr > th > div {
    padding: 0.25rem 0.5rem;
}