div.details-user-stories > div.details-user-story-item {
    padding: 0.5rem 1rem;
}

.details-user-story-item.selected {
    background: #f1f1f1;
}

.details-user-story-item:hover {
    background: #dadada;
    cursor: pointer;
}

span.us-name {
    font-weight: bold;
    padding-right: 1rem;
}