#formCard {
    -webkit-box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.42);
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.42);
    overflow: visible !important;
    min-height: 200px;
}

.info-panel{
    float:right;
    color:black;
}

.info-panel-error{
    color:red;
    float:right;
}