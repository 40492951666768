div.user-story-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 4px;
}

div.user-story-status > div {
    height: 12px;
    border-width: 3px;
    border-style: solid;
    border-radius: 4px;
    width: 100%;
}

div.indicator-legend {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 1rem;

    & > .legend-item {
        display: flex;
        flex-direction: row-reverse;
        direction: ltr;
        justify-content: space-between;
        align-items: center;

        & > div.legend-icon {
            height: 12px;
            width: 32px;
            border-width: 3px;
            border-style: solid;
            border-radius: 4px;
        }
    }
}
