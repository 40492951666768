.login {
    margin-top: 40px;
}

.registration {
    margin-top: 30px;
}

.account {
    margin-top: 50px;
    width: 40vw;
}

.fieldInput {
    margin-bottom: 5px;
}

label {
    float: left;
}

.error-feedback {
    height: 10px;
    display: block;
}