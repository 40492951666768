div.container {
    max-width: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 0.5rem;
    background: #e4e4e4;
    grid-template-columns: 2fr 7fr 2fr;
    width: 100vw;
    height: calc(100vh - 78px);
    overflow: hidden;
}

.content {
    position: relative;
    height: calc(100vh - 78px);
    width: 100%;
    display: grid;
    grid-template-rows: 2fr auto;
}

.actions {
    height: 100%;
    width: 100%;
    background: white;
}