div.summary-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    border-width: 2px;
    border-color: black;
    border-style: solid;
    border-radius: 8px;
}

div.exercise-result-content {
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

div.trace-element-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem 0;
}

div.trace-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    &.overview {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
    }

    & > div.stats {
        justify-self: end;
    }
}

div.target-artefact-elements {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: end;
}