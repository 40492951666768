/* CSS for Productbacklog div */
.productBacklog {
    width: 250px;
    height: 100%;
    position: fixed;
    text-align: center;
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 1px;

    z-index: 1;

    -webkit-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    -moz-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
}

.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.backlog-open{
    margin-top: -100px;
    transition: all 0.2s ease-out;
}

#dropZoneBacklog.drop-target{
    background-color: transparent;
}