.state {
    color: black;
    font-weight: bold;
    text-align: center;
    width: 250px;
    flex: 0 0 auto;
    height: 400px;
    position:relative;
}

.state:not(:first-child) {
    border-left: 1px solid black;
}

.state-title {
    font-size: 15px;
    height: 10%;
}

.dropzone {
    width: 250px;
    height: 90%;
    z-index: 0;
    transition: all 0.2s ease-out;
}

.drop-target {
    background-color: #f5f5f5;
    transition: all 0.2s ease-out;
}