#container-account {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    height: 100vh;
}

#columnAvatar {
    background-color: black;
    display: flex;
    justify-content: center;
    width: 100%;
}

#avatar {
    margin-top: 20vh;
}

#columnForm {
    background-color: #00dc89;
    display: flex;
    justify-content: center;
    width: 100%;
}

.accountTitle {
    color: white;
    margin-top: 5vh;
    text-align: center;
}

.buttonAccountSettings {
    margin-top: 40px;
    text-align: center;
}