div.details-selected-user-story {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 1rem 1rem 2rem 1rem;
    gap: 0.5rem;
    align-items: end;

    div.name {
        text-align: end;
        color: #787878;
        font-size: 14px;
    }

    div.value {
        display: flex;
        gap: 0.25rem;
    }
}

div.value > span.selected {
    background: #d6d6d6;
}

div.value > span.underline {
    border-bottom: solid 2px;
    border-bottom-color: #b80d0d;
}