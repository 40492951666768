div#tooltip, div#menu {
    background: white;
    position: absolute;
    border-radius: 0.5rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

div#tooltip {
    padding: 1rem;
    display: grid;
    gap: 0.25rem 1rem;
    grid-template-columns: auto auto;
}

div#menu {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
}

div#menu > div {
    padding: 0.5rem 1rem;
}

div#menu > div:hover {
    background: lightgray;
    cursor: pointer;
}

svg.tracing-graph {
    background: white;
    grid-row: 1;
}

svg > g > circle:hover {
    cursor: pointer;
}
