.mini {
    position: relative;
    width: 10rem;
    height: auto;
    touch-action: none;
    user-select: none;
    margin-top: 5px;
    display: block;
    transition: transform 0.2s ease-out;
}

.middle {
    width: 10rem;
    touch-action: none;
    user-select: none;
    transition: margin 0.2s ease-out, transform .2s ease-out;
}

#buttonIconStyle {
    color: black;
    border-color: transparent;
    background-color: transparent;
    padding: 0;
    margin: 0;
    vertical-align: top;
}

#headerStyle {
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 5px;
    margin: 0;
}

#bodyStyle {
    font-size: 13px;
    font-weight: normal;
}

.descriptionStyle {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
}

.drag-start {
    -webkit-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    -moz-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    transition: none;
}

.fourButton {
    text-align: right;
    vertical-align: top;
}

.idStyle {
    padding-top: 5px;
}

.firstRow {
    text-align: left;
}

