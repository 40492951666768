.formGroup {
    width: 100%;
    margin-left: 10px;
}

.caret {
    float: right;
}

.checkboxColor {
    float: left;
    width: 100%;
}

.listCheckboxButton {
    width: 100%;
    background-color: transparent;
    color: black;
    border: none;
    text-align: left;
    font-weight: bold;
    padding-left: 0;
    text-decoration: none;
}

.listCheckboxButton:hover {
    background-color: transparent;
    color: #00dc89;
}

.listCheckboxButton:focus {
    background-color: transparent;
    color: black;
    outline: none;
    box-shadow: none;
}

.listElements {
    width: fit-content;
}

