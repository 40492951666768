.sidebarTitle {
    padding-top: 10px;
}

.filteringDiv {
    text-align: center;
    width: 100%;
}

.navOfLists {
    width: 100%;
}