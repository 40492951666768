.newSprint {
    background-color: #f2f2f2;
}

.deleteSprintButton {
    float: right;
}

.themeColorField {
    padding: 2px;
}