span.sub-artefact-item {
    padding: 0.25rem;
}

span.sub-artefact-item {
    cursor: default;
}

span.sub-artefact-item.write {
    cursor: pointer;
}

span.sub-artefact-item.selectable {
    background-color: #eaeaea;
}

span.sub-artefact-item.write:hover {
    background: #c6c6c6;
}

span.sub-artefact-item.selected {
    border: 2px solid #3e3e3e !important;
    padding: calc(0.25rem - 2px);
}

span.sub-artefact-item.marked {
    border-bottom: solid 2px;
}

