div.details-selected-artefact {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 1rem;
    gap: 0.5rem;
    align-items: end;

    div.name {
        text-align: end;
        color: #787878;
        font-size: 14px;
    }
}

div.artefact-actions {
    display: flex;
    justify-content: space-evenly;
    height: 3rem;

    div.action {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        width: 100%;
    }

    div.action:hover {
        background: #dadada;
        cursor: pointer;
    }
}