.paragraph1 {
    margin-left: 10px;
    margin-bottom: 0;
    margin-right: 4px;
    font-weight: bold
}

.paragraph2 {
    margin-bottom: 0;
}

.connection-status {
    right: 10px;
    padding: 0 25px;
}

.connection-status-ok {
    color: #00dc89;
}

.connection-status-nok {
    color: red;
}

.tracing-link {
    padding: 0 1rem;
}

.tracing-link:hover {
    cursor: pointer;
}
