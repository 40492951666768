.cardStyle {
    width: 13rem;
    height: 12rem;
    margin: 1vw;
    border: solid black;
    transition: all 0.2s ease;
}

.cardStyle.projectCard:hover{
    -webkit-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    -moz-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    transition: all 0.2s ease;

    cursor: pointer;
}

#iconX {
    position: absolute;
    right: 0;
    top: 1px;
}

#iconPencil {
    position: absolute;
    right: 30px;
    top: 6px;
}

#alert {
    width: 50vw;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
}

#memberButton {
    background-color: transparent;
    border: none;
    color: black;
}

#cardTitleProjectOwn {
    text-align: left;
    margin-left: 10px;
    width: 135px;
}

#cardTitleProjectInvited {
    text-align: left;
    margin-left: 10px;
}

#descriptionTitleProject {
    text-align: left;
    margin-left: 10px;
}

#descriptionProject {
    text-align: left;
    margin-left: 10px;
    height: 100px;
    width: auto;
    overflow: hidden;
}

#startDateProject {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}


.card-body {
    padding: 5px;
}

.buttonIconStyleProject {
    color: black;
    background-color: transparent;
    padding: 0;
    margin: 0;
    vertical-align: top;
    border:none;
}

.buttonIconStyleProject:hover{
    color:black;
    background-color: transparent;
    border: none;
}

.buttonIconStyleProject:focus {
    color:black;
    outline: none;
    box-shadow: inset 0 -1px 0 #ddd;
    border:none;
}

.buttonIconStyleProject:active {
    color:black !important;
    outline: none !important;
    box-shadow: inset 0 -1px 0 #ddd;
    border:none;
}

