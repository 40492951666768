svg.class-diagram {
    background: white;
}

svg > g > rect:hover {
    cursor: pointer;
}

svg > g > text:hover {
    cursor: pointer;
}