.container-tutorial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.tutorial-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 8fr 1fr;
    flex-direction: column;
    text-align: center;
}

.tutorial-content-top {
    grid-row: 1;
}

.tutorial-content-center {
    grid-row: 2;
}

.tutorial-content-bottom {
    grid-row: 3;
}

.tutorial-content-top, .tutorial-content-center, .tutorial-content-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tutorial-next-button, .tutorial-back-button {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background: #f3f3f3;
    border: none;

    & > div.chevron {
        height: 3rem;
        margin: 0.5rem;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        background: #e3e3e3;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.tutorial-next-button:hover, .tutorial-back-button:hover {
    background: #eaeaea;
}