#projectSettingsCardContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
}

#projectSettingsCardContainer .card {
    min-width: 300px;
    margin: 0.25em 0.5em;
    overflow: hidden;
    flex: 1;
    height: auto;
}

h1#projectSettingsTitle {
    margin: 5px;
    text-align: center;
}