.container-login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    height: 100%;
}
.StoryScreenTitle{
    color: #989797;
    font-size: 6vw;
    margin-top: 3vh;
}

#row1Login {
    background-color: black;
    width: 100%;
    text-align: center;
}

#imageLogin {
    margin-top: 15vh;
    width: 20vw;
}

#row2Login {
    background-color: #00dc89;
    width: 100%;
    padding: 0 5vw;
    text-align: center;
}

.buttonLogin {
    margin-top: 40px;
}

.loginTitle {
    margin-top: 15vh;
    color: white;
}

#inputFields {
    margin-top: 8vh;
}

#style {
    margin-top: 100px;
}

.loginLink {
    color: white;
}

.buttonLogin {
    margin-top: 40px;
}

.loginText {
    margin-top: 3vh;
}

#errorMessage {
    color: #d9534f;
    border: #d9534f solid 2px;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 2px;
    margin: 2vh auto;
    width: 50%;
}


