#cardStyleCreate {
    width: 13rem;
    height: 12rem;
    margin: 1vw;
    border: solid black;
}

#cardBody {
    padding: 2px 1px 1px;
}

#cardTitle {
    margin: 0;
    font-size: 30px;
}

a {
    color: black;
}

#cardStyleCreate:hover {
    text-decoration: none;
    color:black;
    -webkit-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    -moz-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    transition: all 0.2s ease;
    cursor: pointer;
}

a:active {
    text-decoration: none;
}