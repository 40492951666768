div.details-container {
    grid-row: 2;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 0.5rem;
}

div.details-user-stories {
    grid-row: 1 / 3;
    background: white;
    padding: 1rem 0;
}

div.details-selected-user-story-container {
    grid-column: 2;
    grid-row: 1;
    background: white;
    display: grid;
}

div.details-selected-artefact-container {
    grid-column: 2;
    grid-row: 2;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}