.user-story-item {
    display: grid;
    grid-template-columns: 7fr 1fr;
    gap: 0.5rem;
    padding: 1rem;
    direction: ltr;
    align-items: center;
    text-align: start;

    &.selected {
        background-color: #dfdfdf;
    }

    &:hover {
        background: #dadada;
        cursor: pointer;
    }
}

.user-story-menu-container {
    max-height: calc(100vh - 78px);
    overflow-y: scroll;
    scrollbar-width: thin;
    direction: rtl;
}

.user-story-list {
    display: flex;
    flex-direction: column;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
}

.buttons > button {
    border: none;
    outline: none;
    background: none;
    transition: all ease 250ms;
    padding: 0.5rem;
}

.buttons > button:hover {
    background: #dadada;
}

.user-story-name {
    font-weight: bold;
}

.user-story-content {
    font-size: 12px;
}

.user-story-checkbox {
    max-height: 1.5rem;
    min-height: 1rem;
}
