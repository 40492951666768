div.exercise-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 3fr 1fr;
    gap: 1rem;
    background: white;

    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}