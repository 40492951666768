.btn-primary-custom-btn-primary {
    background-color: #00dc89;
    color: white;
}

.btn-primary-custom-btn-secondary {
    background-color: #989797;
    color: white;
}

.btn-primary-custom-btn-naked {
    background-color: transparent;
    color: black;
}

.btn-primary-custom-btn-delete {
    background-color: darkred;
    color: white;
}

.btn-primary-custom-btn-submit {
    background-color: transparent;
    color: black;
    border: 1px black solid;
    border-radius: 5px;
}

.btn-primary-custom-btn-submit:hover {
    color: #989797;
    border: 1px solid #989797;
    transition: all 0.5s ease-out;
}

.btn-primary-custom-btn-addUUserStory {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    transition: all 0.5s ease-out;
}

.btn-primary-custom-btn-addUUserStory:hover {
    color: #00dc89;
    border: 1px solid #00dc89;
    transition: all 0.5s ease-out;
}

.btn-primary-custom-btn-filtering {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    transition: all 0.5s ease-out;
    margin: 5px;
}

.btn-primary-custom-btn-filtering:hover {
    color: #00dc89;
    border: 1px solid #00dc89;
    transition: all 0.5s ease-out;
}