#circle {
    background-color: #00dc89;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#text {
    color: black;
    font-family: "Times New Roman", Times, serif;
    transform: rotate(-20deg);
}