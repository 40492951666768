.bg-black {
    background-color: black;
    color: white;
}

.nav-link {
    border-left: 1px solid white;
}

.navbar-brand {
    margin-left: 10px;
}

/**
    Button green (for prject overview)
 */

.btn-greenColor:focus {
    outline: none;
    box-shadow: none;
}

.btn-greenColor:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
}

.btn-greenColor:hover {
    color: #00dc89;
    transition: color 0.5s ease-out;
}

.iconBlock {
    display: block;
    padding: 0;
    margin-right: 10px;
}

.iconName {
    font-size: 10px;
    margin: 0;
}

/**
    Account Dropdown
 */
.accountDropDown {
    background-color: black;
    -webkit-box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.42);
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.42);
    left: -100px;
    top: 60px;
}

.accountDropDown .dropDownItem {
    color: white;
}

.dropDownItem:focus {
    background-color: #00dc89;
}

.dropDownItem, a {
    text-decoration: none;
}

.accountDropDown .dropDownItem:hover {
    color: black;
}

.navItemPosition {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/**
    Gear roll button
 */
.btn-roll:hover .gear {
    transition: all 0.5s ease-out;
    color: #00dc89;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.btn-roll:hover {
    color: #00dc89;
    transition: color 0.5s ease-out;
}

.btn-roll {
    margin-top: 10px;
    font-size: 30px;
    color: white;
}

.gear {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    margin-bottom: 5px;
}

.btn-roll:focus {
    outline: none;
    box-shadow: none;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.bg-light {
    -webkit-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    -moz-box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
    box-shadow: 0 6px 10px 0 rgba(50, 50, 50, 0.76);
}
