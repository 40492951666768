div.trace-side-bar-container {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

div.trace-side-bar-artefacts {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

div > button.save-trace-button {
    background: #00dc89;
}

div > button.save-trace-button, div > button.submit-exercise-trace-button {
    border: none;
    outline: none;
    padding: 0.5rem;
    border-radius: 4px;
    transition: all 200ms ease;

    &:hover {
        background: #2efbad;
    }

    &:disabled {
        background: lightgray;
    }
}

div.trace-side-bar-button-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

div.selected-artefact-detail {
    text-transform: lowercase;
}