.container-projectView {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    height: 100%;
}

#invitedRow {
    background-color: white;
    width: 100%;
    text-align: center;
    border-right: black solid 2px;
}

#invitedElements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

#ownElements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

#createRow {
    background-color: #989797;
    width: 100%;
    text-align: center;
}

#ownRow {
    background-color: white;
    width: 100%;
    text-align: center;
    border-left: black solid 2px;
}

#titleRow {
    padding-top: 10vw;
    color: black;
    font-size: 2vw;
}

#titleDiv {
    position: absolute;
    margin-left: auto;
    padding-top: 10px;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: white;
}

#titlePage {
    margin-bottom: 2vh;
}

