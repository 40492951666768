.filter-item {
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
    gap: 0.5rem;
    margin: 0 1rem;
    max-height: 2rem;
}

.filter-container {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

div.color {
    aspect-ratio: 1 / 1;
    max-height: 1.5rem;
}

.toggle.react-toggle--checked .react-toggle-track {
    background: #3131e3;
}

.toggle:hover {
    background: transparent;
}

.toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: #1717da;
}

.toggle.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #3131e3;
}

button.create-exercise-button {
    border: none;
    outline: none;
    padding: 0.5rem;
    border-radius: 4px;
    transition: all 200ms ease;
    margin: 0 1rem;
}