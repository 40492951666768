a.tutorial-link {
    padding: 1rem;
    background: #00dc89;
    border-radius: 4px;
    transition: all 200ms ease;
    text-decoration: none;
    color: black;

    &:hover {
        background: #10c17e;
    }
}

div.exercise-container {
    height: calc(100% - 74px);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(10, 1fr);

    & > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem;

        & > h2 {
            padding: 0 0.5rem;
        }
    }
}

div.current-exercises {
    grid-column: 1;
    grid-row: 1 / 6;
}

div.completed-exercises {
    grid-column: 1;
    grid-row: 6 / 11;
}

div.created-exercises {
    grid-column: 2;
    grid-row: 1 / 10;
}

div.exercise-tutorial {
    grid-column: 2;
    grid-row: 10;
    justify-content: end;
    align-items: end;
    padding: 0 2rem;
}

div > div.exercise-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: thin;
}

div.exercise-status.in-progress {
    color: green;
}

a.exercise-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    transition: all 200ms ease;
    text-decoration: none;

    &:hover {
        background: #eaeaea;
        cursor: pointer;
    }
}
