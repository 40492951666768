div.modal-input-group {
    display: flex;
    flex-direction: column;
}

div.modal-input-group-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-items: center;
}

div.modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

div.modal-button-row {
    display: flex;
    justify-content: end;
    gap: 1rem;

    & > input {
        width: 100%;
    }

    & > button {
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
    }
}

div.sub-artefact-select {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background: #f1f1f1;
    padding: 1rem;
    justify-content: center;
}

div.sub-artefact-item {
    padding: 0.25rem;
}

div.sub-artefact-item {
    cursor: default;
}

div.sub-artefact-item.write {
    cursor: pointer;
}

div.sub-artefact-item.write:hover {
    background: #dadada;
}

div.sub-artefact-item.selected {
    border: 2px solid #00dc89;
    padding: calc(0.25rem - 2px);
}